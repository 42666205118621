<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <Toast />
                <h5>{{ $t('menu.shopFinancialHistory') }}</h5>
                <div class="p-grid">
                    <div class="p-col-12 p-md-2">
                        <h6>{{ $t('general.date') }}</h6>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <SelectButton v-model="intervalToggle" :options="intervalOptions" optionLabel="name" />
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Calendar ref="startDateCalendar" style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="startDateModel"></Calendar>
                            <label>{{ $t('sportsbets.startDate') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2" v-show="intervalToggle.id == 1">
                        <span class="p-float-label">
                            <Calendar style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="endDateModel"></Calendar>
                            <label>{{ $t('sportsbets.endDate') }}</label>
                        </span>
                    </div>
                </div>
                <div class="p-grid">
                    <div class="p-col-12 p-md-2">
                        <h6>{{ $t('general.shop') }}</h6>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <SelectButton v-model="shopsToggle" :options="shopsOptions" optionLabel="name" />
                    </div>
                    <div class="p-col-12 p-md-2" v-show="shopsToggle.id == 0">
                        <span class="p-float-label">
                            <Dropdown :showClear="true" style="width: 100%" v-model="shopsModel" :options="shops" optionLabel="name" :filter="!this.isMobile()" />
                            <label>{{ $t('dropdown.placeholder.shop') }}</label>
                        </span>
                    </div>
                </div>
                <div class="p-grid">
                    <div class="p-col-12 p-md-2">
                        <h6>{{ $t('general.recomputeSolds') }}</h6>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <SelectButton v-model="soldToggle" :options="soldOptions" optionLabel="name" />
                    </div>
                </div>
                <div class="p-grid">
                    <div class="p-col-12 p-md-2">
                        <h6>{{ $t('general.reimportThirdPartyData') }}</h6>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <SelectButton v-model="thirdPartyToggle" :options="soldOptions" optionLabel="name" />
                    </div>
                    <div class="p-col-12 p-md-4" v-if="thirdPartyToggle.id == 1">
                        <Checkbox v-model="thirdParties[0].selected" :binary="true" />
                        <label>{{ thirdParties[0].name }}</label>
                    </div>
                </div>
                <div class="p-grid">
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <InputText style="width: 100%" type="text" v-model="comments" />
                            <label>{{ $t('general.actionDetails') }}</label>
                        </span>
                    </div>
                </div>
                <div class="p-grid">
                    <div class="p-col-6 p-md-3"><Button type="button" :label="$t('buttons.recomputeFinancialHistory')" :loading="showLoader" class="p-button-raised p-button-success" @click="showModal()" /></div>
                </div>
            </div>
            <Dialog :header="$t('reports.dialog.recomputeHistoryTitle')" v-model:visible="modalVisible" :breakpoints="{ '960px': '75vw' }" :style="{ width: '30vw' }" :modal="true">
                <p>
                    {{
                        this.$t('reports.dialog.recomputeHistoryContent', {
                            period: periodOption,
                            sold: soldOption,
                            parties: partiesOption,
                            shop: shopText,
                        })
                    }}
                </p>
                <template #footer>
                    <Button :label="$t('buttons.close')" @click="cancel()" icon="pi pi-times" class="p-button-danger" />
                    <Button :label="$t('buttons.confirm')" @click="computeHistory()" icon="pi pi-check" class="p-button-success" />
                </template>
            </Dialog>
        </div>
    </div>
</template>

<script>
import { BoApi } from '../../../service/BoApi';
import { BoReportsApi } from '../../../service/BoReportsApi';
import settings from '../../../settings/generalSettings';

export default {
    name: 'financialHistory',
    data() {
        var self = this;
        return {
            BoApi: new BoApi(),
            BoReportsApi: new BoReportsApi(),
            startDateModel: null,
            endDateModel: null,
            errorKeyCount: 0,
            showError: false,
            shops: [],
            shopsModel: { name: 'All', _id: 'all' },
            soldToggle: { id: 1 },
            thirdPartyToggle: { id: 1 },
            intervalToggle: { id: 0 },
            shopsToggle: { id: 0 },
            modalVisible: false,
            comments: '',
            confirmModal: false,
            confirmModalText: '',
            showLoader: false,
            thirdParties: [{ id: settings.products.nsoft, name: self.$t('products.nsoft'), selected: true }],
        };
    },
    mounted() {
        let crtDate = new Date();
        let year = crtDate.getFullYear();
        let month = crtDate.getMonth() + 1;
        if (month <= 9) {
            month = '0' + month;
        }
        let day = crtDate.getDate();
        if (day <= 9) {
            day = '0' + day;
        }
        var startDateString = year + '-' + month + '-' + day;
        var endDateString = year + '-' + month + '-' + day;
        this.startDateModel = new Date(startDateString);
        this.endDateModel = new Date(endDateString);
        this.intervalToggle = this.intervalOptions[0];
        this.shopsToggle = this.shopsOptions[1];
        this.soldToggle = this.soldOptions[1];
        this.thirdPartyToggle = this.soldOptions[1];
        this.getShops();
    },
    computed: {
        intervalOptions() {
            return [
                {
                    id: 0,
                    name: this.$t('general.oneDay'),
                },
                {
                    id: 1,
                    name: this.$t('general.interval'),
                },
            ];
        },
        soldOptions() {
            return [
                {
                    id: 0,
                    name: this.$t('general.no'),
                },
                {
                    id: 1,
                    name: this.$t('general.yes'),
                },
            ];
        },
        shopsOptions() {
            return [
                {
                    id: 0,
                    name: this.$t('general.oneShop'),
                },
                {
                    id: 1,
                    name: this.$t('general.all'),
                },
            ];
        },
        periodOption() {
            return this.intervalToggle == 1 ? this.formatEnDate(this.startDateModel) : this.formatEnDate(this.startDateModel) + ' until ' + this.formatEnDate(this.endDateModel);
        },
        soldOption() {
            return this.soldToggle.id == 1 ? 'yes' : 'no';
        },
        shopText() {
            return this.shopsToggle.id == 1 ? 'All' : this.shopsModel.name;
        },
        partiesOption() {
            if (this.thirdPartyToggle.id == 0) {
                return 'none';
            } else {
                var parties = '';
                for (var i = 0; i < this.thirdParties.length; i++) {
                    if (this.thirdParties[i].selected) {
                        parties += this.thirdParties[i].name + ',';
                    }
                }
                if (parties != '') {
                    return parties.substring(0, parties.length - 1);
                }
                return parties;
            }
        },
    },
    methods: {
        getShops() {
            this.BoApi.getShops('')
                .then((shopsResponse) => {
                    let shopsDdlValues = [];
                    for (var i = 0; i < shopsResponse.data.length; i++) {
                        let shopItem = {
                            name: shopsResponse.data[i].code + ' - ' + shopsResponse.data[i].name,
                            code: shopsResponse.data[i].code,
                            _id: shopsResponse.data[i]._id,
                        };
                        shopsDdlValues.push(shopItem);
                    }
                    this.shops = shopsDdlValues;
                    this.addShops = shopsDdlValues;
                    this.shops.unshift(this.shopsModel);
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        computeHistory() {
            this.modalVisible = false;
            this.showLoader = true;
            var requestObj = {
                ComputeSolds: this.soldToggle.id == 1 ? true : false,
                ReimportNsoft: false,
                ReimportWinner: false,
                ReimportSyncron: false,
                StartDate: this.formatEnDate(this.startDateModel),
                EndDate: this.intervalToggle.id == 1 ? this.formatEnDate(this.startDateModel) : this.formatEnDate(this.endDateModel),
                Comments: this.comments,
                Shop: this.shopsToggle.id == 1 ? 'all' : this.shopsModel._id,
            };
            if (this.thirdPartyToggle.id == 1) {
                this.thirdParties.forEach((e) => {
                    switch (e.id) {
                        case settings.thirdParties.nsoft: {
                            requestObj.ReimportNsoft = e.selected;
                            break;
                        }
                        case settings.thirdParties.winner: {
                            requestObj.ReimportWinner = e.selected;
                            break;
                        }
                        case settings.thirdParties.syncron: {
                            requestObj.ReimportSyncron = e.selected;
                            break;
                        }
                    }
                });
            }
            console.log(requestObj);
            this.BoReportsApi.soldsHistory(requestObj)
                .then(() => {
                    this.showToast('success', this.$t('reports.dialog.recomputeOk'));
                    this.showLoader = false;
                })
                .catch((error) => {
                    this.showLoader = false;
                    this.checkForUnauthorizedError(error);
                    this.showToast('error', this.$t('alerts.generalError'));
                });
        },
        showModal() {
            this.modalVisible = true;
        },
        cancel() {
            this.modalVisible = false;
        },
        showToast(toastSeverity, text) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: text, life: this.getToastLifeSeconds() });
        },
    },
};
</script>